import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { NSProjectDetailContextType } from "./ProjectDetailContext.type";
import {
  Order_By,
  Video_Interview_Question_Event_Type_Enum,
  Video_Interview_Status_Enum,
  Video_Interview_Type_Enum,
} from "../../gql/graphql";

import i18n from "../../library/i18next";
import { NSDropdown } from "../../components/Dropdown/Dropdown.type";
import { NSDropdownWithCaptionType } from "../../components/DropdownWithCaption/DropdownWithCaption.type";
import { useDebounce } from "@uidotdev/usehooks";

const ProjectDetailContext =
  createContext<NSProjectDetailContextType.IJobDescriptionContext>({
    checkedVideoStatusItems: undefined,
    selectedOrder: 1,
    searchString: "",
    searchInput: undefined,
    dropdowns: undefined,
    listOrders: undefined,
  });

function ProjectDetailProvider({ children }: { children: ReactNode }) {
  const [checkedVideoStatusItems, setCheckedVideoStatusItems] =
    useState<NSProjectDetailContextType.CheckedItemType>([]);
  const [searchString, setSearchString] = useState("");
  const [selectedOrder, setSelectedOrder] = useState<number>(1);

  const debouncedSearchString = useDebounce(searchString, 300);

  const searchInput = {
    id: "dashboardSearchInputId",
    name: "dashboardSearchInputName",
    onChange: (value: string) => {
      setSearchString(value);
    },
    value: searchString,
  };

  const listOrders: NSProjectDetailContextType.listOrdersType = useMemo(
    () => ({
      1: { client_candidate: { name: Order_By.Asc } },
      2: { client_candidate: { name: Order_By.Desc } },
      3: { created_at: Order_By.Desc },
      4: { created_at: Order_By.Asc },
    }),
    []
  );

  const filterDropdownItems: NSDropdown.IDropdownItem[] = useMemo(
    () => [
      {
        id: Video_Interview_Status_Enum.NotSet,
        label: i18n.t("projects.noChangesYet"),
      },
      {
        id: Video_Interview_Status_Enum.LinkSent,
        label: i18n.t("projects.invitationSend"),
      },
      {
        id: Video_Interview_Status_Enum.CandidateInProgress,
        label: i18n.t("projects.interviewStarted"),
      },
      {
        id: Video_Interview_Status_Enum.VideoSent,
        label: i18n.t("projects.interviewCompleted"),
      },
      {
        id: Video_Interview_Status_Enum.Scored,
        label: i18n.t("projects.scored"),
      },
      {
        id: Video_Interview_Status_Enum.Expired,
        label: i18n.t("projects.expired"),
      },
      {
        id: Video_Interview_Status_Enum.InReview,
        label: i18n.t("projects.inReview"),
      },
      {
        id: Video_Interview_Status_Enum.InappropriateContent,
        label: i18n.t("projects.inappropriateContent"),
      },
      {
        id: Video_Interview_Status_Enum.InsufficientContent,
        label: i18n.t("projects.insufficientContent"),
      },
      {
        id: "warnedInterview",
        label: i18n.t("projects.warnedInterview"),
      },
      {
        id: Video_Interview_Status_Enum.VideoSent,
        label: i18n.t("projects.sentEnglishTest"),
      },
      {
        id: Video_Interview_Status_Enum.Scored,
        label: i18n.t("projects.scoredEnglishTest"),
      },
      {
        id: Video_Interview_Status_Enum.CandidateNotInterested,
        label: i18n.t("projects.candidateNotInterested"),
      },
      {
        id: Video_Interview_Status_Enum.NotCompleted,
        label: i18n.t("projects.notCompleted"),
      },
    ],
    []
  );

  const onCheckedItems = useCallback((items: NSDropdown.IDropdownItem[]) => {
    const preparedItems = items.map((item) => {
      switch (item.id) {
        case "warnedInterview":
          return {
            video_interviews: {
              _or: [
                {
                  video_interview_type: {
                    _eq: Video_Interview_Type_Enum.CompetencyAssessment,
                  },
                  interview_last_status: {
                    _eq: Video_Interview_Status_Enum.Scored,
                  },
                },
                {
                  video_interview_type: {
                    _eq: Video_Interview_Type_Enum.EnglishAssessment,
                  },
                  interview_last_status: {
                    _eq: Video_Interview_Status_Enum.Scored,
                  },
                },
              ],
              video_interview_questions: {
                video_interview_question_events: {
                  _or: [
                    {
                      type: {
                        _eq: Video_Interview_Question_Event_Type_Enum.VisibilityHidden,
                      },
                    },
                    {
                      type: {
                        _eq: Video_Interview_Question_Event_Type_Enum.WindowBlurred,
                      },
                    },
                    {
                      type: {
                        _eq: Video_Interview_Question_Event_Type_Enum.WindowResized,
                      },
                    },
                  ],
                },
              },
            },
          };
        case i18n.t("projects.sentEnglishTest"):
        case i18n.t("projects.scoredEnglishTest"):
          return {
            video_interviews: {
              video_interview_type: {
                _eq: Video_Interview_Type_Enum.EnglishAssessment,
              },
              interview_last_status: {
                _eq: item.label
                  ? (item.id as Video_Interview_Status_Enum)
                  : Video_Interview_Status_Enum.NotSet,
              },
            },
          };
        case Video_Interview_Status_Enum.NotSet:
          return {
            video_interviews_aggregate: {
              count: {
                predicate: { _eq: 0 },
              },
            },
          };
        default:
          return {
            video_interviews: {
              video_interview_type: {
                _eq: Video_Interview_Type_Enum.CompetencyAssessment,
              },
              interview_last_status: {
                _eq: item.label
                  ? (item.id as Video_Interview_Status_Enum)
                  : Video_Interview_Status_Enum.NotSet,
              },
            },
          };
      }
    });

    setCheckedVideoStatusItems(preparedItems as any);
  }, []);

  const dropdowns: NSDropdownWithCaptionType.IDropdownWithCaption[] = useMemo(
    () => [
      {
        id: 1,
        prefixTitle: i18n.t("projects.sortBy"),
        type: "basic",
        selectedDropdownItem: {
          id: 1,
          label: i18n.t("projects.nameAsc"),
        },
        onSelect: (_id, selected) => {
          setSelectedOrder((selected?.id as number) ?? 1);
        },

        options: [
          {
            id: 1,
            label: i18n.t("projects.nameAsc"),
          },
          {
            id: 2,
            label: i18n.t("projects.nameDesc"),
          },
          {
            id: 3,
            label: i18n.t("projects.dateAsc"),
          },
          {
            id: 4,
            label: i18n.t("projects.dateDesc"),
          },
        ],
      },
      {
        prefixTitle: i18n.t("projects.filterBy"),
        id: 2,
        placeholder: i18n.t("projects.status"),
        type: "withCheckbox",
        defaultCheckedItems: [],
        onCheckedItems: (items) => {
          onCheckedItems(items);
        },
        options: filterDropdownItems,
      },
    ],
    [filterDropdownItems, onCheckedItems]
  );

  const value = useMemo(
    () => ({
      checkedVideoStatusItems,
      selectedOrder,
      searchString: debouncedSearchString.toLowerCase(),
      searchInput,
      dropdowns,
      listOrders,
    }),
    [
      checkedVideoStatusItems,
      selectedOrder,
      debouncedSearchString,
      searchInput,
      dropdowns,
      listOrders,
    ]
  );

  return (
    <ProjectDetailContext.Provider value={value}>
      {children}
    </ProjectDetailContext.Provider>
  );
}

function useProjectDetailContext() {
  const context = useContext(ProjectDetailContext);
  if (!context) {
    throw Error(" please use in ProjectDetailProvider");
  }

  return context;
}

export { ProjectDetailProvider, useProjectDetailContext };
